<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
    style="width:150%"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
//   import theme from '@core/components/charts/echart/theme.json'
//   ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },

  props: {
    projects: {
      type: Object,
      requried: true,
    },
  },

  data() {
    return {
      totalData: [],

      option: {
        legend: {
          selectedMode: false,
        },
        grid: {
          left: 100,
          right: 100,
          top: 50,
          bottom: 50,
        },
        yAxis: {
          type: "value",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        series: [],
      },
    };
  },

  methods: {
    onCreate() {
      const Internal = [];
      const External = [];
      const rawData = [];
      const xAxisData = [];
      // Populate Internal array
      for (const key in this.projects.Internal) {
        if (this.projects.Internal.hasOwnProperty(key)) {
          const value = this.projects.Internal[key];
          xAxisData.push(key); // Assuming 'key' is the property containing the x-axis data
          Internal.push(value); // Assuming 'value' is the property containing the project count
        }
      }

      for (const key in this.projects.External) {
        if (this.projects.External.hasOwnProperty(key)) {
          const value = this.projects.External[key];
          External.push(value); // Assuming 'value' is the property containing the project count
        }
      }

      this.option.xAxis.data = xAxisData; // Set x-axis data outside the loop

      // Push Internal and External arrays to rawData array
      rawData.push(Internal);
      rawData.push(External);

      for (let i = 0; i < rawData[0].length; ++i) {
        let sum = 0;
        for (let j = 0; j < rawData.length; ++j) {
          sum += rawData[j][i];
        }
        this.totalData.push(sum);
      }

      const seriesList = ["Internal", "External"];
      this.option.series = seriesList.map((name, sid) => {
        return {
          name,
          type: "bar",
          stack: "total",
          barWidth: "40%",
          label: {
            show: true,
          },
          data: rawData[sid],
        };
      });
    },
  },

  created() {
    this.onCreate();
  },
};
</script>
