<template>

<div class="statistics-container">
    <div v-for="item in statisticsItems" :key="item.icon" class="statistics-item">
    
        <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">{{ getValue(item.subtitle) }}</h2>
            <span style="font-size:small">{{ item.subtitle }}</span>
        </div>
    </div>
</div>

  </template>
  
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    createdStatus: {
      type: Number,
      requried: true,
    },
    quoteStatus: {
      type: Number,
      requried: true,
    },
    chargeStatus: {
      type: Number,
      requried: true,
    },
    processingStatus: {
      type: Number,
      requried: true,
    },
    boxStatus: {
      type: Number,
      requried: true,
    },
    completedStatus: {
      type: Number,
      requried: true,
    },
  },

  data() {
    return {
      statisticsItems: [
        {
          color: 'light-primary',
          title: '25',
          subtitle: 'Draft',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          color: 'info',
          title: '30',
          subtitle: 'Quote',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          color: 'light-danger',
          title: '5',
          subtitle: 'Charge String',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          color: 'light-success',
          title: '10',
          subtitle: 'Processing',
          customClass: '',
        },
        {
          color: 'light-success',
          title: '25',
          subtitle: 'In the Box',
          customClass: '',
        },
        {
          color: 'light-success',
          title: '30',
          subtitle: 'Completed',
          customClass: '',
        },
      ],
    }
  },
  methods: {
    getValue(status){
      if(status == 'Draft'){
        return this.createdStatus;
      }else if(status =='Quote'){
        return this.quoteStatus;

      }else if(status =='Charge String'){
        return this.chargeStatus;

      }else if(status =='Processing'){
        return this.processingStatus;

      }else if(status =='In the Box'){
        return this.boxStatus;

      }else if(status =='Completed'){
        return this.completedStatus;

      }


    },
  },
}
</script>
<style scoped>
    .statistics-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap; /* Allow items to wrap to the next line */
        gap: 15px; /* Spacing between items */
        width: 100%;
        border:1px solid gray;
        margin-top: 2%;
        margin-bottom:3%;
    }

    .statistics-item {
        flex-basis: calc(25% - 15px); /* Set the width of each item */
        max-width: 100px; /* Set maximum width for each item */
        padding: 10px;
        border-radius: 10px;
        text-align: center;
    }

    .statistics-item h2 {
        margin-bottom: 5px;
        font-weight: bold;
    }

    .statistics-item span {
        color: #666;
    }
</style>
