<template>

 
    <div class="text-center">
        <b-row class="mt-2" >
            <b-col>

            <section class="pdf-item">

                <h4>Solstice Monthly System Report {{ reportDate }}</h4>

            </section>
        </b-col>

        </b-row>

  


     
        <b-row>
            <b-col>
                <section class="pdf-item">
     
 
     <span>
         <statuses :createdStatus="createdStatus" :quoteStatus="quoteStatus" :chargeStatus="chargeStatus" :processingStatus="processingStatus" :boxStatus="boxStatus" :completedStatus="completedStatus"></statuses>
     </span>
 </section>
            </b-col>
           


     
    </b-row>

        <b-row v-if="projectData.Internal != null">
            <b-col>

            <section class="pdf-item">
        <strong>
            Projects Created
        </strong>
 
        <span>
            <project-bar-chart  :projects="projectData"></project-bar-chart>
        </span>
    </section>

    </b-col>


            <strong></strong>


        <b-col>
            <section class="pdf-item" style="border:0.5px solid gray;">

            <strong>Average Timespans</strong>
            <span>

            <average-timeline :createTimespan="createTimespan" :quoteTimespan="quoteTimespan" :chargeTimespan="chargeTimespan" :processTimespan="processTimespan" :boxTimespan="boxTimespan" ></average-timeline>
        </span>
    </section>

        </b-col>

    </b-row>
    <div class="html2pdf__page-break"></div>

    <b-row>

        <b-col>
            <section class="pdf-item">
                <strong>Cost Breakdown of Quotes</strong>

                
                    <span>

            <cost-breakdown :breakdown="breakdown"></cost-breakdown>
            </span>


          
            </section>
        </b-col>



    
    </b-row>

    <b-row>



<b-col>
    <section class="pdf-item">

    <strong>Material Popularity</strong>
    <span>

    <materials :material-counts="materialCounts"></materials>
</span>
</section>

</b-col>




</b-row>

<b-row v-if="rejectCounts.length>0">



        <b-col>
            <section class="pdf-item">
                <strong>Reject Reasons</strong>

                    <span>
            <reject-reasons :reject-counts="rejectCounts"></reject-reasons>
                </span>
                    


                <div class="html2pdf__page-break"></div>

            </section>
        </b-col>


    
    </b-row>


    </div>
  
   

  </template>
  
<script>
import ProjectBarChart from './ProjectBarChart.vue';
import CostBreakdown from './CostBreakdown.vue';
import Materials from './Materials.vue';
import Statuses from './Statuses.vue';
import RejectReasons from './RejectReasons.vue';
import AverageTimeline from './AverageTimeline.vue';
import { BCard, BRow, BCol, } from 'bootstrap-vue';
import store from '@/store';
import dashboardStoreModule from './dashboardStoreModule.js';
import { ref, onUnmounted, reactive } from '@vue/composition-api';

export default {
components: {
    ProjectBarChart,
    CostBreakdown,
    AverageTimeline,
    Statuses,
    CostBreakdown,
    RejectReasons,
    Materials,
    BCard, BRow, BCol,
    

},
setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'dashboard';
    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME);
    });
  },
data() {
      return {
        projectData : {'Internal' : null, 'External' : null},
        breakdown : [],
        materialCounts:[],
        rejectCounts:[],
        createTimespan  : null, 
          quoteTimespan  : null, 
          chargeTimespan  : null, 
          processTimespan  : null, 
          boxTimespan  : null, 
        
          createdStatus  : null, 
          quoteStatus  : null, 
          chargeStatus  : null, 
          processingStatus  : null, 
          boxStatus  : null, 
          completedStatus  : null, 
          reportDate : null,


      }
    },

mounted() {
    this.getItems();
},
methods: {
    getItems(){
        store
        .dispatch('dashboard/getReportData')
        .then((response) => {
          this.projectData = response.data.projectData;
          this.breakdown = response.data.breakdown;
          this.materialCounts = response.data.materialCounts;
          this.rejectCounts = response.data.rejectCounts;


          this.createTimespan = response.data.createTimespan;
          this.quoteTimespan = response.data.quoteTimespan;
          this.chargeTimespan = response.data.chargeTimespan;
          this.processTimespan = response.data.processTimespan;
          this.boxTimespan = response.data.boxTimespan;

          this.createdStatus = response.data.createdStatus;
          this.quoteStatus = response.data.quoteStatus;
          this.chargeStatus = response.data.chargeStatus;
          this.processingStatus = response.data.processingStatus;
          this.boxStatus = response.data.boxStatus;
          this.completedStatus = response.data.completedStatus;          
          this.reportDate = response.data.reportDate;
        
        })
        .catch((error) => {});

    },
    
}
};
</script>