<template>
    <div class="media-list">
      <b-media vertical-align="top">
        <template #aside>
          <div class="time-span bg-primary"></div>
        </template>
        <h5 class="media-heading text-primary">
          Draft
        </h5>
        <b-card-text class="mb-0">
         {{ createTimespan }} days on average
        </b-card-text>
      </b-media>
      <b-media vertical-align="top">
        <template #aside>
            <div class="time-span bg-danger"></div>

        </template>
        <h5 class="media-heading text-danger">
          Quote
        </h5>
        <b-card-text class="mb-0">
        {{ quoteTimespan }} days on average
        </b-card-text>
      </b-media>

      <b-media vertical-align="top">
        <template #aside>
            <div class="time-span bg-dark"></div>

        </template>
        <h5 class="media-heading text-dark">
         Charge String
        </h5>
        <b-card-text class="mb-0">
          {{ chargeTimespan }} days on average
        </b-card-text>
      </b-media>

      <b-media vertical-align="top">
        <template #aside>
            <div class="time-span bg-warning"></div>

        </template>
        <h5 class="media-heading text-warning">
          Processing
        </h5>
        <b-card-text class="mb-0">
          {{ processTimespan }} days on average
        </b-card-text>
      </b-media>

      

      <b-media vertical-align="top">
        <template #aside>
            <div class="time-span bg-success"></div>

        </template>
        <h5 class="media-heading text-success">
          In the box
        </h5>
        <b-card-text class="mb-0">
          {{ boxTimespan }} days on average
        </b-card-text>
      </b-media>
    </div>
  </template>
  
  <script>
  import { BMedia, BImg } from 'bootstrap-vue'
  
  export default {
    components: {
      BMedia,
      BImg,
    },

    props: {
      createTimespan: {
      
      requried: true,
    },
    quoteTimespan: {
      
      requried: true,
    },
    chargeTimespan: {
      
      requried: true,
    },
    processTimespan: {
      
      requried: true,
    },
    boxTimespan: {
      
      requried: true,
    },
  },

  }
  </script>
<style scoped>
.time-span{
    width:30px;
    height:20px;
    border:1px solid gray;
    margin-top:10px;
}

</style>