<template>
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <e-charts
        ref="line"
        autoresize
        :options="option"
        theme="theme-color"
        auto-resize
      />
    </div>

    <table>
      <tr v-for="(x, index) in option.series[0].data">
        <td>
          <strong>{{ x.name }}</strong>
        </td>
        <td>
          {{ x.value }}
        </td>
      </tr>
    </table>

    <div></div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
//   import theme from '@core/components/charts/echart/theme.json'
//   ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    materialCounts: {
      type: Array,
      requried: true,
    },
  },

  data() {
    return {
      option: {
        title: {
          text: "Materials Popularity",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Materials",
            type: "pie",
            radius: "50%",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 15,
                shadowOffsetX: 5,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    onCreate() {
      if (this.materialCounts && this.materialCounts.length > 0) {
        this.option.series[0].data = this.materialCounts;
      }
    },
  },

  created() {
    this.onCreate();
  },
  watch: {
    materialCounts: {
      immediate: true, // Trigger the handler immediately when the component is created
      handler(newVal, oldVal) {
        this.onCreate();
      },
    },
  },
};
</script>
<style scoped>
/* CSS for the table */
table {
  width: 25%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px;
}

th {
  background-color: #f2f2f2;
}

/* CSS for alternating row colors */
tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
