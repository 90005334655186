<template>
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <e-charts
        ref="line"
        autoresize
        :options="option"
        theme="theme-color"
        auto-resize
      />
    </div>

    <table>
      <tr v-for="(x, index) in option.series[0].data">
        <td>
          <strong>{{ x.name }}</strong>
        </td>
        <td>
          {{ x.value }}
        </td>
      </tr>
    </table>

    <div></div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/treemap";
//   import theme from '@core/components/charts/echart/theme.json'
//   ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    rejectCounts: {
      type: Array,
      requried: true,
    },
  },

  data() {
    return {
      option: {
        series: [
          {
            type: "treemap",
            roam: "move",
            nodeClick: false,
            animation: false,
            breadcrumb: {
              show: false,
            },
            data: [],
          },
        ],
      },
    };
  },

  methods: {

    onCreate() {
      if (this.rejectCounts && this.rejectCounts.length > 0) {
        this.option.series[0].data = this.rejectCounts;
      }
    },
  },

  created() {
    this.onCreate();
  },
  watch: {
    rejectCounts: {
      immediate: true, // Trigger the handler immediately when the component is created
      handler(newVal, oldVal) {
        this.onCreate();
      },
    },
  },
};
</script>

<style scoped>
/* CSS for the table */
table {
  width: 25%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px;
}

th {
  background-color: #f2f2f2;
}

/* CSS for alternating row colors */
tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
