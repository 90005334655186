import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
   

    getReportData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getReportData`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

  },
};
